import { Box } from "@mui/material"
import React from "react"
import { H2 } from "../text"

export function DisableMobile() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        p: "2rem",
        alignItems: "center",
      }}
    >
      <H2 title="Sorry, Beyond Presence is currently not supported on your device. Please use your laptop or desktop." />
    </Box>
  )
}
