import { Box } from "@mui/material"
import React from "react"
import { Colors } from "../../types"

export function WhiteBox({
  children,
  width = "50%",
}: {
  children: React.ReactNode
  width?: string
}) {
  return (
    <Box
      sx={{
        backgroundColor: Colors.white,
        borderRadius: "0.75rem",
        width,
        p: "1rem",
        mx: "2rem",
        my: "1.5rem",
      }}
    >
      {children}
    </Box>
  )
}
