import { ThemeProvider } from "@emotion/react"
import { useMediaQuery } from "@mui/material"
import CssBaseline from "@mui/material/CssBaseline"
import { useTheme } from "@mui/material/styles"
import { getAnalytics } from "firebase/analytics"
import { initializeApp } from "firebase/app"
import { getAuth, onAuthStateChanged } from "firebase/auth"
import { getFirestore } from "firebase/firestore"
import { getStorage } from "firebase/storage"
import mixpanel from "mixpanel-browser"
import React, { useEffect, useState } from "react"
import { createBrowserRouter, RouterProvider } from "react-router-dom"
import { Bounce, ToastContainer } from "react-toastify"
import { StripePaymentModal } from "../components/modals"
import { FullScreenLoader } from "../components/ui/fullScreenLoader"
import {
  AppContext,
  AppDispatchContext,
  appReducer,
  initialState,
  usePersistedReducer,
} from "../context"
import { useAppDispatch, useAppSelector } from "../redux"
import { AppActions } from "../redux/appSlice"
import {
  checkCurrentUsage,
  createCreator,
  getCreator,
  isUserAdmin,
} from "../services"
import { getCreatorFromUser, StateAction } from "../types"
import { isDev, isLocalDev } from "../utils"
import { getRouterData } from "./routes/router"

if (!isLocalDev) {
  mixpanel.init(
    isDev
      ? "e46f49d9d64303e14eadb47197eb3929"
      : "2c7730f569fa81a3a26dfffeac4d6a68",
    {
      debug: false,
      persistence: "localStorage",
    },
  )
}

const firebaseConfig = isDev
  ? {
      apiKey: "AIzaSyC788lZ2gkp8HjPNDhpWNBHsLCVUGaOksY",
      authDomain: "bey-dev.firebaseapp.com",
      projectId: "bey-dev",
      storageBucket: "bey-dev.appspot.com",
      messagingSenderId: "347029147333",
      appId: "1:347029147333:web:8c9c85b285179c0be4eaac",
      measurementId: "G-NBGKVM0BES",
    }
  : {
      apiKey: "AIzaSyCx2GDcIjQQ9nTtIbHHf6wGwvnp_2V7jX4",
      authDomain: "bey-prod.firebaseapp.com",
      projectId: "bey-prod",
      storageBucket: "bey-prod.appspot.com",
      messagingSenderId: "352012720018",
      appId: "1:352012720018:web:27a46e753a2e56983f534b",
      measurementId: "G-BN8E3VD31V",
    }

const app = initializeApp(firebaseConfig)
export const analytics = getAnalytics(app)
export const auth = getAuth(app)
export const db = getFirestore(app)
export const storage = getStorage(app)
auth.useDeviceLanguage()

function App() {
  const { state, dispatch } = usePersistedReducer(
    appReducer,
    initialState,
    "state",
  )
  const [loading, setLoading] = useState(true)

  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"))

  const showStripeModal = useAppSelector((state) => state.app.showStripeModal)
  const reduxDispatch = useAppDispatch()

  const handleCloseStripeModal = () => {
    reduxDispatch(AppActions.updateShowStripeModal(false))
  }

  const router = createBrowserRouter(
    getRouterData(state.user, state.userEnteredPassword, isMobile),
  )

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        if (!isLocalDev) {
          mixpanel.identify(user.uid)
          mixpanel.people.set({
            $name: user.displayName || "",
            $email: user.email,
          })
        }

        try {
          const creator = await getCreator(user.uid)
          dispatch({
            type: StateAction.setUser,
            payload: {
              uid: user.uid,
              name: user.displayName || "",
              email: user.email,
              subscriptionPlan: creator.subscriptionPlan,
              subscriptionState: creator.subscriptionState,
              subscriptionBillingCycleAnchor:
                creator.subscriptionBillingCycleAnchor,
              termsAccepted: creator.termsAccepted ? true : false,
            },
          })
        } catch (error) {
          const creator = await createCreator(
            getCreatorFromUser({
              email: user.email,
              name: user.displayName || "",
              uid: user.uid,
              termsAccepted: false,
            }),
          )
          dispatch({
            type: StateAction.setUser,
            payload: {
              uid: user.uid,
              name: user.displayName || "",
              email: user.email,
              termsAccepted: creator.termsAccepted ? true : false,
            },
          })
          console.error("Error fetching creator:", error)
        }

        const isAdmin = await isUserAdmin()
        dispatch({ type: StateAction.setUserAdmin, payload: isAdmin })
        setLoading(false)
      } else {
        dispatch({ type: StateAction.resetState })
        if (!isLocalDev) {
          mixpanel.reset()
        }
      }
      setLoading(false)
    })
    return () => {
      unsubscribe()
    }
  }, [])

  useEffect(() => {
    const checkUserSubscription = async () => {
      reduxDispatch(AppActions.updateShowStripeModal(false))

      if (state.user && state.user.uid) {
        try {
          if (
            state.user.subscriptionPlan &&
            state.user.subscriptionBillingCycleAnchor
          ) {
            const usageDetails = await checkCurrentUsage(state.user.uid)
            dispatch({
              type: StateAction.setUsageDetails,
              payload: usageDetails,
            })
          } else {
            reduxDispatch(AppActions.updateShowStripeModal(true))
            const usageDetails = {
              current_usage: 0,
              usage_limit: 1000,
            }
            dispatch({
              type: StateAction.setUsageDetails,
              payload: usageDetails,
            })
          }
        } catch (error) {
          console.error("Error checking user subscription:", error)
        }
      }
    }

    checkUserSubscription()
  }, [state.user, dispatch, reduxDispatch])

  if (loading) {
    return <FullScreenLoader />
  }

  return (
    <AppContext.Provider value={state}>
      <AppDispatchContext.Provider value={dispatch}>
        <ThemeProvider theme={theme}>
          <CssBaseline enableColorScheme />
          <RouterProvider router={router} />
          <ToastContainer
            position="bottom-center"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="dark"
            transition={Bounce}
          />
        </ThemeProvider>
        <StripePaymentModal
          visible={showStripeModal}
          onClose={handleCloseStripeModal}
        />
      </AppDispatchContext.Provider>
    </AppContext.Provider>
  )
}

export default App
