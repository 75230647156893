import { Grid, Pagination, Skeleton } from "@mui/material"
import React, { useEffect, useState } from "react"
import { useNavigate } from "react-router-dom"
import { toast } from "react-toastify"
import { MainLayout, SnackbarWithText, Topbar } from "../../../components"
import { DeletePopup } from "../../../components/ui/deletePopup"
import ResponsiveVideoPlayerModal from "../../../components/video/ResponsiveVideoPlayerModal"
import { VideosList } from "../../../components/video/videosList"
import { useAppContext, useAppDispatchContext } from "../../../context"
import { useAppDispatch } from "../../../redux"
import { AppActions } from "../../../redux/appSlice"
import {
  deleteVideo,
  getAnalyticsProperties,
  getLoadVideoUrl,
  listVideos,
  logAnalyticsEvent,
} from "../../../services"
import {
  AnalyticsEvents,
  AnalyticsProducts,
  ApiState,
  initialSnackbarProps,
  isTypeofVideo,
  ModuleName,
  PageMode,
  Screen,
  StateAction,
  Video,
  VideoScreen,
} from "../../../types"
import { handleProcessingEntities, loadVideos } from "../../../utils"

export function TextToVideoScreen() {
  const [videos, setVideos] = useState<Video[]>([])
  const [snackbarProps, setSnackbarProps] = useState(initialSnackbarProps)
  const [isLoading, setIsLoading] = useState(true)
  const [deletePopupOpen, setDeletePopupOpen] = useState(false)
  const [videoToDelete, setVideoToDelete] = useState<Video | null>(null)
  const [videoToPreview, setVideoToPreview] = useState<Video | null>(null)
  const [allVideos, setAllVideos] = useState<Video[]>([])
  const [currentPageVideos, setCurrentPageVideos] = useState<Video[]>([])
  const [page, setPage] = useState(1)
  const [totalPages, setTotalPages] = useState(1)
  const videosPerPage = 6

  const navigate = useNavigate()
  const state = useAppContext()
  const dispatch = useAppDispatchContext()

  const reduxDispatch = useAppDispatch()

  const [isInitialLoading, setIsInitialLoading] = useState(true)

  useEffect(() => {
    const fetchAllVideos = async () => {
      try {
        const fetchedVideos = (await listVideos()).filter(
          (e) => e.campaignId == null,
        )
        setAllVideos(fetchedVideos)
        setTotalPages(Math.ceil(fetchedVideos.length / videosPerPage))

        dispatch({
          type: StateAction.setVideos,
          payload: fetchedVideos,
        })

        const processingVideos = fetchedVideos.filter(
          (e) => e.state === ApiState.processing,
        )
        handleProcessingEntities(
          processingVideos,
          state.user,
          dispatch,
          setSnackbarProps,
        )
      } catch (error) {
        console.error("Error fetching videos:", error)
        setSnackbarProps({ open: true, text: "Error loading videos" })
      } finally {
        setIsInitialLoading(false)
      }
    }

    fetchAllVideos()
  }, [dispatch])

  useEffect(() => {
    const loadCurrentPageVideos = async () => {
      if (allVideos.length === 0) {
        setCurrentPageVideos([])
        setIsLoading(false)
        return
      }

      setIsLoading(true)
      const startIndex = (page - 1) * videosPerPage
      const endIndex = startIndex + videosPerPage
      const pageVideos = allVideos.slice(startIndex, endIndex)

      const loadedVideos = await loadVideos(
        pageVideos.filter((e) => e.state === ApiState.ready),
      )

      setCurrentPageVideos(
        pageVideos.map(
          (video) => loadedVideos.find((v) => v.id === video.id) || video,
        ),
      )
      setIsLoading(false)
    }

    loadCurrentPageVideos()
  }, [page, allVideos])

  useEffect(() => {
    const processingVideos = videos.filter(
      (e) => e.state === ApiState.processing,
    )
    if (processingVideos.length === 0) {
      return
    }

    processingVideos.forEach(async (video) => {
      const stateVideo = state.videos.find((v) => v.id === video.id)
      if (stateVideo.state === ApiState.ready) {
        const loaded = await loadVideos([stateVideo])
        setVideos((prevVideos) =>
          prevVideos.map((v) => (v.id === loaded[0].id ? loaded[0] : v)),
        )
      }
    })
  }, [state.videos])

  useEffect(() => {
    if (videoToPreview && isTypeofVideo(videoToPreview)) {
      const req = async () => {
        if (!videoToPreview.url) {
          const url = await getLoadVideoUrl(videoToPreview.id).catch((e) => "")
          setVideoToPreview((prevVideo) => ({ ...prevVideo, url }))
        }
      }
      req()
    }
  }, [videoToPreview])

  const handlePreviewVideo = (item: Video) => {
    setVideoToPreview(item)
  }

  const createNewVideo = async () => {
    logAnalyticsEvent(
      AnalyticsEvents.ttv_started,
      getAnalyticsProperties(AnalyticsProducts.text_to_video),
    )
    reduxDispatch(AppActions.updatePageMode(PageMode.CREATE))
    reduxDispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const deleteSelectedVideo = async () => {
    if (!videoToDelete) return

    setIsLoading(true)
    try {
      await deleteVideo(videoToDelete)
      setAllVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )
      setCurrentPageVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )
      setVideos((prevVideos) =>
        prevVideos.filter((video) => video.id !== videoToDelete.id),
      )

      dispatch({
        type: StateAction.deleteVideo,
        payload: videoToDelete,
      })

      const newTotalPages = Math.ceil((allVideos.length - 1) / videosPerPage)
      setTotalPages(newTotalPages)
      if (currentPageVideos.length === 1 && page > 1) {
        setPage((prevPage) => Math.min(prevPage - 1, newTotalPages))
      }

      toast.success("Video deleted successfully")
    } catch (e) {
      console.error("Error deleting video:", e)
      setSnackbarProps({ open: true, text: "Error deleting video" })
    } finally {
      setIsLoading(false)
      setDeletePopupOpen(false)
      setVideoToDelete(null)
    }
  }

  const cancelDeletion = () => {
    setVideoToDelete(null)
    setDeletePopupOpen(false)
  }

  const SkeletonLoader = () => (
    <Grid container spacing={2} sx={{ padding: 2 }}>
      {[...Array(6)].map((_, index) => (
        <Grid item xs={12} sm={6} md={4} key={index}>
          <Skeleton variant="rounded" width="100%" height={200} />
          <Skeleton variant="text" width="80%" height={24} sx={{ mt: 1 }} />
        </Grid>
      ))}
    </Grid>
  )

  const handleEdit = async (video: Video) => {
    reduxDispatch(AppActions.updateSelectedVideo(video))
    reduxDispatch(AppActions.updatePageMode(PageMode.UPDATE))
    reduxDispatch(AppActions.updateOpenedModule(ModuleName.videos))
    navigate(`/${Screen.videos}/${VideoScreen.selectVideoAvatar}`)
  }

  const handleDelete = (video: Video) => {
    setVideoToDelete(video)
    setDeletePopupOpen(true)
  }

  const handleClosePreview = () => {
    setVideoToPreview(null)
  }

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    value: number,
  ) => {
    setPage(value)
  }

  return (
    <MainLayout>
      <SnackbarWithText props={snackbarProps} />
      <Topbar title="Videos" cta={"New Video +"} onClick={createNewVideo} />
      {isInitialLoading || isLoading ? (
        <div style={{ padding: 16 }}>
          <SkeletonLoader />
        </div>
      ) : (
        <>
          <VideosList
            videos={currentPageVideos}
            onDeleteVideo={handleDelete}
            onEditVideo={handleEdit}
            onPreviewVideo={handlePreviewVideo}
          />
          <Pagination
            count={totalPages}
            page={page}
            onChange={handlePageChange}
            color="primary"
            sx={{ mt: 2, display: "flex", justifyContent: "center" }}
          />
        </>
      )}
      <DeletePopup
        open={deletePopupOpen}
        onConfirm={deleteSelectedVideo}
        onCancel={cancelDeletion}
        text={`Delete video "${videoToDelete?.name}"?`}
      />
      <ResponsiveVideoPlayerModal
        videoUrl={videoToPreview?.url}
        previewUrl={videoToPreview?.previewUrl}
        isOpen={!!videoToPreview}
        onClose={handleClosePreview}
      />
    </MainLayout>
  )
}
