import { Box, Stack } from "@mui/material"
import React from "react"
import { H2, MainButton } from "../../components"

export function Topbar({
  title,
  cta,
  onClick,
}: {
  title: string
  cta: string
  onClick: () => void
}) {
  return (
    <Box
      sx={{
        height: "3rem",
        py: "0.75rem",
        px: "1.25rem",
        my: "1.5rem",
        mx: "2rem",
        alignItems: "center",
      }}
    >
      <Stack
        direction="row"
        spacing={0}
        justifyContent={"space-between"}
        alignItems={"center"}
      >
        <H2 title={title} />
        <MainButton onClick={onClick} text={cta} />
      </Stack>
    </Box>
  )
}
