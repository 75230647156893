import { Typography } from "@mui/material"
import React from "react"
import { Colors } from "../../types"

export function DescriptionText({
  children,
  color = Colors.grey800,
}: {
  children: React.ReactNode
  color?: Colors
}) {
  return (
    <Typography
      fontSize="0.875rem"
      textTransform={"none"}
      fontWeight="400"
      lineHeight="120%"
      color={color}
    >
      {children}
    </Typography>
  )
}
