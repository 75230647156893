import {
  EmbeddedCheckout,
  EmbeddedCheckoutProvider,
} from "@stripe/react-stripe-js"
import { loadStripe } from "@stripe/stripe-js"
import React, { useCallback } from "react"
import { createCheckoutSession } from "../../services"
import { CheckoutData } from "../../types"
import { isDev } from "../../utils"

// Make sure to call `loadStripe` outside of a component’s render to avoid
// recreating the `Stripe` object on every render.
const stripePromise = loadStripe(
  isDev
    ? "pk_test_51PRCjc2MW3vXJI7rHLUdJZ96pXwVnfPpRTMvc1pYsolAkCd0yCAsBPqmfNfJEHbTdSSu1YXjUkzcAbCviCLtfb7200r07bsbsH"
    : "pk_live_51PRCjc2MW3vXJI7rMmsyzAg1fJ5UE37iaP8lsNO0yFhBCvax72hqeRymyw5JYMxpPNB2vcFb87f39G5ShD3jkfIt00zMHTJpQD",
)

export function Checkout({ data }: { data: CheckoutData }) {
  const fetchClientSecret = useCallback(async () => {
    try {
      const res = await createCheckoutSession(data)
      return res.clientSecret
    } catch (e) {
      console.error(e)
    }
  }, [])

  const options = { fetchClientSecret }

  return (
    <div id="checkout">
      <EmbeddedCheckoutProvider stripe={stripePromise} options={options}>
        <EmbeddedCheckout />
      </EmbeddedCheckoutProvider>
    </div>
  )
}
