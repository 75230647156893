export enum AnalyticsEvents {
  ttv_started = "ttv_started",
  avatar_creation_started = "avatar_creation_started",
  ttv_process_cancelled = "ttv_process_cancelled",
  process_cancelled = "process_cancelled",
  avatar_process_cancelled = "avatar_process_cancelled",
  avatar_selected = "avatar_selected",
  preview_requested = "preview_requested",
  videos_requested = "videos_requested",
  video_requested = "video_requested",
  avatar_requested = "avatar_requested",
  payment_requested = "payment_requested",
  payment_provided = "payment_provided",
  video_creation_started = "video_creation_started",
  avatar_processing_started = "avatar_processing_started",
  training_video_uploaded = "training_video_uploaded",
  avatar_generation_requested = "avatar_generation_requested",
  consent_shown = "consent_shown",
  consent_provided = "consent_provided",
  knowledge_file_uploaded = "knowledge_file_uploaded",
}

export enum AnalyticsProducts {
  text_to_video = "text_to_video",
  personalized_outreach = "personalized_outreach",
  avatar_creation = "avatar_creation",
  agent_creation = "agent_creation",
}

export enum AnalyticsProperties {
  product = "product",
  numberOfVideos = "Number of videos",
  amount = "amount",
}

export type AnalyticsDict = Partial<
  Record<keyof typeof AnalyticsProperties, string | number>
>
