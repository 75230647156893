import { Typography } from "@mui/material"
import React from "react"
import { Colors } from "../../types"

export function BoldText({ text }: { text: string }) {
  return (
    <Typography
      fontSize="1rem"
      textTransform={"none"}
      fontWeight="700"
      lineHeight="120%"
      color={Colors.almostBlack}
    >
      {text}
    </Typography>
  )
}
