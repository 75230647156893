import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
} from "@mui/material"
import React, { useState } from "react"
import { useAppDispatchContext } from "../../context"
import { StateAction } from "../../types"

export function PasswordDialog() {
  const dispatch = useAppDispatchContext()
  const [open, setOpen] = useState(false)
  const [password, setPassword] = useState("")

  const handleClickOpen = () => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        alignItems: "center",
      }}
    >
      <Button variant="outlined" onClick={handleClickOpen}>
        Unlock Beyond Presence
      </Button>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event: React.FormEvent<HTMLFormElement>) => {
            event.preventDefault()
            handleClose()
          },
        }}
      >
        <DialogTitle>Unlock Beyond Presence</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Please enter the password you have received from the team.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="password"
            label="Password"
            type="password"
            onChange={(e) => setPassword(e.target.value)}
            value={password}
            fullWidth
            variant="standard"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button
            type="submit"
            onClick={() =>
              dispatch({
                type: StateAction.setUserEnteredPassword,
                payload: password,
              })
            }
          >
            Submit
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  )
}
