import { Action, State, StateAction } from "../types"

export const initialState: State = {
  user: null,
  outreachCampaigns: [],
  avatars: [],
  videos: [],
  userEnteredPassword: "",
  isUserAdmin: false,
  usageDetails: {
    current_usage: 0,
    usage_limit: 0,
  },
}

const safeSortByUpdatedAt = (a: any, b: any) => {
  const getTime = (date: any) => {
    if (date instanceof Date) return date.getTime()
    if (typeof date === "string") return new Date(date).getTime()
    return 0 // fallback for undefined or invalid dates
  }
  return getTime(b.updatedAt) - getTime(a.updatedAt)
}

export const appReducer = (state: State, action: Action): State => {
  switch (action.type) {
    case StateAction.resetState:
      return initialState
    case StateAction.setUser:
      return { ...state, user: action.payload }
    case StateAction.addOutreachCampaign:
      return {
        ...state,
        outreachCampaigns: state.outreachCampaigns
          .concat(action.payload)
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.deleteOutreachCampaign:
      return {
        ...state,
        outreachCampaigns: state.outreachCampaigns
          .filter((e) => e.id !== action.payload.id)
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.updateOutreachCampaign:
      return {
        ...state,
        outreachCampaigns: state.outreachCampaigns
          .map((e) => (e.id === action.payload.id ? action.payload : e))
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.setOutreachCampaigns:
      return {
        ...state,
        outreachCampaigns: action.payload.sort(safeSortByUpdatedAt),
      }
    case StateAction.setAvatars:
      return {
        ...state,
        avatars: action.payload,
      }
    case StateAction.addAvatar:
      return {
        ...state,
        avatars: state.avatars.concat(action.payload).sort(safeSortByUpdatedAt),
      }
    case StateAction.updateAvatar:
      return {
        ...state,
        avatars: state.avatars
          .map((e) => (e.id === action.payload.id ? action.payload : e))
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.setVideos:
      return {
        ...state,
        videos: action.payload.sort(safeSortByUpdatedAt),
      }
    case StateAction.addVideo:
      return {
        ...state,
        videos: state.videos.concat(action.payload).sort(safeSortByUpdatedAt),
      }
    case StateAction.updateVideo:
      return {
        ...state,
        videos: state.videos
          .map((e) => (e.id === action.payload.id ? action.payload : e))
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.deleteVideo:
      return {
        ...state,
        videos: state.videos
          .filter((e) => e.id !== action.payload.id)
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.deleteAvatar:
      return {
        ...state,
        avatars: state.avatars
          .filter((e) => e.id !== action.payload.id)
          .sort(safeSortByUpdatedAt),
      }
    case StateAction.setUserEnteredPassword:
      return { ...state, userEnteredPassword: action.payload }
    case StateAction.setUserAdmin:
      return { ...state, isUserAdmin: action.payload }
    case StateAction.setUsageDetails:
      return { ...state, usageDetails: action.payload }
    default:
      throw new Error(`Unhandled action type: ${action}`)
  }
}
