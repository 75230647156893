import { Box } from "@mui/material"
import React from "react"
import LogoImage from "../../assets/Logo.svg"

export function Logo() {
  const redirectToHome = () => {
    window.location.href = "/"
  }

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        cursor: "pointer",
        flexGrow: 1,
      }}
      onClick={redirectToHome}
    >
      <img
        src={LogoImage}
        alt="Logo"
        style={{ width: "100%", height: "1.5rem", marginRight: "8px" }}
      />
    </Box>
  )
}
