import * as Sentry from "@sentry/react"
import { signOut } from "firebase/auth"
import { auth } from "../app/App"
import { store } from "../redux"
import { AppActions } from "../redux/appSlice"

export const signUserOut = async () => {
  store.dispatch(AppActions.resetState())

  return await signOut(auth).catch((error) => {
    Sentry.captureException(error)
    console.error(error)
  })
}

export const isUserAdmin = async (): Promise<boolean> => {
  const tokenResult = await auth.currentUser?.getIdTokenResult(true)
  if (!tokenResult?.claims.admin) {
    return false
  }
  return tokenResult.claims.admin as boolean
}
