import { Avatar } from "./Avatar"
import { OutreachCampaign } from "./OutreachCampaign"
import { Screen } from "./Screen"
import { UsageDetails } from "./Usage"
import { User } from "./User"
import { Video } from "./Video"

export interface State {
  outreachCampaigns: OutreachCampaign[]
  avatars: Avatar[]
  videos: Video[]
  userEnteredPassword: string
  user?: User
  isUserAdmin?: boolean
  usageDetails: UsageDetails
}

export enum StateAction {
  setUser = "setUser",
  setScreen = "setScreen",
  addOutreachCampaign = "addOutreachCampaign",
  deleteOutreachCampaign = "deleteOutreachCampaign",
  updateOutreachCampaign = "updateOutreachCampaign",
  setOutreachCampaigns = "setOutreachCampaigns",
  setAvatars = "setAvatars",
  addAvatar = "addAvatar",
  setVideos = "setVideos",
  addVideo = "addVideo",
  updateVideo = "updateVideo",
  resetState = "resetState",
  updateAvatar = "updateAvatar",
  deleteVideo = "deleteVideo",
  deleteAvatar = "deleteAvatar",
  setUserEnteredPassword = "setUserEnteredPassword",
  setUserAdmin = "setUserAdmin",
  setUsageDetails = "setUsageDetails",
}

export type Action =
  | { type: StateAction.setUser; payload: User }
  | { type: StateAction.setScreen; payload: Screen }
  | { type: StateAction.addOutreachCampaign; payload: OutreachCampaign }
  | { type: StateAction.deleteOutreachCampaign; payload: OutreachCampaign }
  | { type: StateAction.updateOutreachCampaign; payload: OutreachCampaign }
  | { type: StateAction.setOutreachCampaigns; payload: OutreachCampaign[] }
  | { type: StateAction.setAvatars; payload: Avatar[] }
  | { type: StateAction.addAvatar; payload: Avatar }
  | { type: StateAction.setVideos; payload: Video[] }
  | { type: StateAction.addVideo; payload: Video }
  | { type: StateAction.updateVideo; payload: Video }
  | { type: StateAction.resetState }
  | { type: StateAction.updateAvatar; payload: Avatar }
  | { type: StateAction.deleteVideo; payload: Video }
  | { type: StateAction.deleteAvatar; payload: Avatar }
  | { type: StateAction.setUserEnteredPassword; payload: string }
  | { type: StateAction.setUserAdmin; payload: boolean }
  | { type: StateAction.setUsageDetails; payload: UsageDetails }
