import { Button } from "@mui/material"
import React from "react"
import { Colors } from "../../types"

export function TextIconButton({
  text,
  startIcon,
  onClick,
  endIcon,
  disabled = false,
}: {
  text: string
  onClick: () => void
  startIcon?: React.ReactNode
  endIcon?: React.ReactNode
  disabled?: boolean
}) {
  return (
    <Button
      variant="text"
      sx={{ textTransform: "none", color: Colors.sideBarDark }}
      onClick={onClick}
      startIcon={startIcon}
      endIcon={endIcon}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
