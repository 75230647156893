import { Alert, Stack } from "@mui/material"
import React from "react"
import { SnackbarProps, SnackbarType } from "../../types"

export function SnackbarWithText({ props }: { props: SnackbarProps }) {
  if (!props.open) {
    return null
  }
  return (
    <Stack alignItems={"center"}>
      <Alert severity={props.severity || SnackbarType.error}>
        {props.text}
      </Alert>
    </Stack>
  )
}
