import { Typography } from "@mui/material"
import React from "react"
import { Colors } from "../../../types"

export function SidebarHeadline({ text }: { text: string }) {
  return (
    <Typography
      fontSize="0.875rem"
      fontWeight="400"
      lineHeight="120%"
      color={Colors.sideBarDarkOpacity}
    >
      {text}
    </Typography>
  )
}
