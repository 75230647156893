import {
  getLoadAvatarUrl,
  getPreviewAvatarUrl,
  getPreviewVideoUrl,
} from "../services"
import { ApiState, Avatar, Video } from "../types"

export const loadAvatarPreviewImages = async (
  avatars: Avatar[],
): Promise<Avatar[]> => {
  const promises = avatars.map(async (e) => ({
    ...e,
    url: "",
    previewUrl: await getPreviewAvatarUrl(e.id).catch((e) => ""),
  }))

  return Promise.all(promises)
}

export const shouldLoadVideos = <T extends Avatar | Video>(
  elements: T[],
): boolean => {
  return elements.some((e) => e.url == null || e.url === "")
}

export const loadAvatarVideos = async (
  avatars: Avatar[],
): Promise<Avatar[]> => {
  if (!shouldLoadVideos(avatars)) {
    return avatars
  }

  const promises = avatars
    .filter((e) => e.state === ApiState.ready)
    .map(async (e) => {
      const url = await getLoadAvatarUrl(e.id).catch((e) => "")
      const previewUrl = await getPreviewAvatarUrl(e.id).catch((e) => "")
      return {
        ...e,
        url,
        previewUrl,
      }
    })
  return Promise.all(promises)
}

export const loadVideoPreviewImages = async (
  videos: Video[],
): Promise<Video[]> => {
  const promises = videos
    .filter((e) => e.state === ApiState.ready)
    .map(async (e) => ({
      ...e,
      url: "",
      previewUrl: await getPreviewVideoUrl(e.id).catch((e) => ""),
    }))

  return Promise.all(promises)
}

export const loadVideos = async (videos: Video[]): Promise<Video[]> => {
  if (!shouldLoadVideos(videos)) {
    return videos
  }
  const promises = videos
    .filter((e) => e.state === ApiState.ready)
    .map(async (e) => {
      const previewUrl = await getPreviewVideoUrl(e.id).catch((e) => "")
      return {
        ...e,
        url: "",
        previewUrl,
      }
    })

  return Promise.all(promises)
}
