import MoreHorizOutlinedIcon from "@mui/icons-material/MoreHorizOutlined"
import { MenuItem, MenuItemProps, Menu as MuiMenu } from "@mui/material"
import React, { Fragment, useState } from "react"
import { Colors } from "../types"

type MenuProps = {
  actions: (MenuItemProps & { label: string; icon: JSX.Element })[]
}

export default function Menu(props: MenuProps) {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)

  const open = Boolean(anchorEl)

  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const handleMenuItemClick =
    (action: MenuItemProps & { label: string; icon: JSX.Element }) =>
    (event: React.MouseEvent<HTMLLIElement>) => {
      handleClose()
      if (action.onClick) {
        action.onClick(event)
      }
    }

  return (
    <Fragment>
      <MoreHorizOutlinedIcon
        onClick={handleClick}
        sx={{
          backgroundColor: Colors.grey200,
          borderRadius: "0.25rem",
          width: "2rem",
          height: "2rem",
        }}
      />
      <MuiMenu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          "aria-labelledby": "basic-button",
        }}
      >
        {props?.actions &&
          props?.actions.map((action, index) => {
            if (action) {
              return (
                <MenuItem key={index} onClick={handleMenuItemClick(action)}>
                  {action.icon}
                  {action.label}
                </MenuItem>
              )
            }
          })}
      </MuiMenu>
    </Fragment>
  )
}
