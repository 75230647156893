import { Box, keyframes } from "@mui/material"
import React from "react"
import { Logo } from "./logo"

const pulse = keyframes`
  0% {
    transform: scale(1);
    opacity: 1;
  }
  50% {
    transform: scale(1.1);
    opacity: 0.7;
  }
  100% {
    transform: scale(1);
    opacity: 1;
  }
`

export function FullScreenLoader() {
  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        backgroundColor: "background.default",
      }}
    >
      <Box
        sx={{
          animation: `${pulse} 3s ease-in-out infinite`,
          mb: 4,
        }}
      >
        <Logo />
      </Box>
    </Box>
  )
}
