import { Box, Stack } from "@mui/material"
import React, { useCallback, useState } from "react"
import { Link } from "react-router-dom"
import {
  DescriptionText,
  Logo,
  Popup,
  SidebarHeadline,
  SidebarText,
} from "../../../components"
import { useAppContext } from "../../../context"
import { getStripePortalUrl } from "../../../services/api"
import { Screen, SidebarTextValues } from "../../../types"
import { isDev } from "../../../utils"

export function Sidebar() {
  const [popupOpen, setPopupOpen] = useState(false)
  const state = useAppContext()

  const PopupText = (
    <>
      <DescriptionText>
        {`Please drop us an email with your questions at `}
        <Link to="mailto:support@beyondpresence.ai">
          support@beyondpresence.ai
        </Link>
      </DescriptionText>
    </>
  )

  const handleSettingsClick = useCallback(async () => {
    try {
      const stripePortalUrl = await getStripePortalUrl(
        state.user.uid,
        window.location.href,
      )
      window.open(stripePortalUrl, "_self")
    } catch (error) {
      console.error("Failed to get Stripe portal URL:", error)
      setPopupOpen(true)
    }
  }, [])

  return (
    <Stack
      height={"100%"}
      direction={"column"}
      justifyContent={"space-between"}
    >
      <Box>
        <Box sx={{ mb: "2rem" }}>
          <Logo />
        </Box>
        <Box sx={{ mb: "2rem" }}>
          <Stack>
            <SidebarText
              text={SidebarTextValues.avatars}
              href={`/${Screen.avatars}`}
            />
          </Stack>
        </Box>
        <Box sx={{ mb: "2rem" }}>
          <Stack direction="column" spacing={"0.5rem"}>
            <SidebarHeadline text="Videos" />
            <SidebarText text={SidebarTextValues.textToVideo} href="/" />
            <SidebarText
              text="Outreach Videos"
              href={`/${Screen.outreachCampaigns}`}
            />
          </Stack>
        </Box>
        <Box sx={{ mb: "2rem" }}>
          <Stack direction="column" spacing={"0.5rem"}>
            <SidebarHeadline text="Conversational Agents" />
            <SidebarText
              disabled={!isDev}
              text={SidebarTextValues.myAgents}
              href={`/${Screen.myAgents}`}
            />
            <SidebarText
              text={SidebarTextValues.knowledgeBase}
              href={`/${Screen.knowledgeBase}`}
              disabled={true}
            />
            <SidebarText
              text={SidebarTextValues.conversations}
              href={`/${Screen.conversations}`}
              disabled={true}
            />
            <SidebarText
              text={SidebarTextValues.analytics}
              href={`/${Screen.analytics}`}
              disabled={true}
            />
          </Stack>
        </Box>
      </Box>
      <>
        <Popup
          open={popupOpen}
          handleClose={() => setPopupOpen(false)}
          text={PopupText}
          ctaText="Got it"
          headlineText="Do you need help?"
        />
        <SidebarText
          text={SidebarTextValues.settings}
          href="#"
          onClick={handleSettingsClick}
        />
        <SidebarText
          text={SidebarTextValues.help}
          href={`#`}
          onClick={() => setPopupOpen(true)}
        />
      </>
    </Stack>
  )
}
