import { Dialog } from "@mui/material"
import React, { useState } from "react"

const ResponsiveVideoPlayerModal = ({
  videoUrl,
  previewUrl,
  isOpen,
  onClose,
}) => {
  const [isPlaying, setIsPlaying] = useState(false)

  const handlePlayPause = () => {
    setIsPlaying(!isPlaying)
  }

  return (
    <Dialog open={isOpen} onClose={onClose} maxWidth="md" fullWidth>
      <div style={{ position: "relative", paddingTop: "50%" }}>
        <video
          style={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
          }}
          src={videoUrl}
          poster={previewUrl}
          controls
          playsInline
        >
          Your browser does not support the video tag.
        </video>
      </div>
    </Dialog>
  )
}

export default ResponsiveVideoPlayerModal
