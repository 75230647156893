import { collection, onSnapshot, query, where } from "firebase/firestore"
import { db } from "../app/App"
import {
  Agent,
  ApiState,
  Avatar,
  Collections,
  OutreachCampaign,
  User,
  Video,
} from "../types"

const listenForDoc = async (
  id: string,
  user: User,
  dbCollection: Collections,
) => {
  return new Promise((resolve, reject) => {
    const q = query(
      collection(db, dbCollection),
      where("creatorId", "==", user.uid),
      where("id", "==", id),
    )
    const unsubscribe = onSnapshot(
      q,
      (querySnapshot) => {
        querySnapshot.forEach((doc) => {
          const data = doc.data()
          if ([ApiState.ready, ApiState.error].includes(data.state)) {
            unsubscribe()
            resolve(data)
          }
        })
      },
      (error) => {
        console.error("Error getting video: ", error)
        unsubscribe()
        reject(error)
      },
    )
  })
}

export const listenForVideo = async (
  videoId: string,
  user: User,
): Promise<Video> => {
  return listenForDoc(videoId, user, Collections.video) as Promise<Video>
}

export const listenForAvatar = async (
  avatarId: string,
  user: User,
): Promise<Avatar> => {
  return listenForDoc(avatarId, user, Collections.avatar) as Promise<Avatar>
}

export const listenForCampaign = async (
  campaignId: string,
  user: User,
): Promise<OutreachCampaign> => {
  return listenForDoc(
    campaignId,
    user,
    Collections.campaign,
  ) as Promise<OutreachCampaign>
}

export const listenForAgent = async (
  agentId: string,
  user: User,
): Promise<Agent> => {
  return listenForDoc(agentId, user, Collections.agent) as Promise<Agent>
}
