import { Box, Link, Stack } from "@mui/material"
import React from "react"
import { useRouteError } from "react-router-dom"
import { H2 } from "../components"

export default function ErrorPage() {
  const error = useRouteError()

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        height: "100vh",
        width: "100vw",
        p: "2rem",
        alignItems: "center",
      }}
    >
      <Stack>
        <H2 title="Oops" />
        <p>Sorry, an unexpected error has occurred.</p>
        <p>
          <i>{(error as any).statusText || (error as any).message}</i>
        </p>
        <Link href="/">Go back to the home page</Link>
      </Stack>
    </Box>
  )
}
