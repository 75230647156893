import React from "react"
import { Box, CircularProgress } from "@mui/material"

export function Loader() {
  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100%",
      }}
    >
      <CircularProgress color="primary" value={20} />
    </Box>
  )
}
