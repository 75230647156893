import { Box, Typography } from "@mui/material"
import React from "react"
import { useStripeTable } from "../../hooks"

interface StripePaymentModalProps {
  visible: boolean
  onClose: () => void
}

export function StripePaymentModal({
  onClose,
  visible,
}: StripePaymentModalProps) {
  const StripeTable = useStripeTable()

  if (!visible) return null

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        zIndex: 10,
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(78, 64, 90, 0.40)",
      }}
    >
      <Box
        sx={{
          width: "90%",
          backgroundColor: "white",
          borderRadius: "0.25rem",
        }}
      >
        <Box
          sx={{
            margin: "1rem",
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            position: "relative",
          }}
        >
          <Typography variant="body1">
            Choose a plan that’s right for you
          </Typography>
        </Box>
        <Box sx={{ mb: "1rem" }}>{StripeTable}</Box>
      </Box>
    </Box>
  )
}
