import React from "react"
import {
  listenForAvatar,
  listenForCampaign,
  listenForVideo,
} from "../services/db"
import {
  Action,
  ApiState,
  Avatar,
  EntityTypes,
  isTypeofOutreachCampaign,
  isTypeofVideo,
  OutreachCampaign,
  SnackbarProps,
  SnackbarType,
  StateAction,
  User,
  Video,
} from "../types"

export const handleProcessingEntities = async (
  entities: Video[] | Avatar[] | OutreachCampaign[],
  user: User,
  dispatch: React.Dispatch<Action>,
  setSnackbarProps: (props: SnackbarProps) => void,
) => {
  if (entities.length === 0) {
    return
  }
  const entityType = isTypeofVideo(entities[0])
    ? EntityTypes.video
    : isTypeofOutreachCampaign(entities[0])
      ? EntityTypes.campaign
      : EntityTypes.avatar
  await Promise.all(
    entities.map(async (entity) => {
      const updatedEntity =
        entityType === EntityTypes.video
          ? await listenForVideo(entity.id, user)
          : entityType === EntityTypes.campaign
            ? await listenForCampaign(entity.id, user)
            : await listenForAvatar(entity.id, user)

      dispatch({
        type:
          entityType === EntityTypes.video
            ? StateAction.updateVideo
            : entityType === EntityTypes.campaign
              ? StateAction.updateOutreachCampaign
              : StateAction.updateAvatar,
        payload: updatedEntity as any,
      })

      if (updatedEntity.state === ApiState.error) {
        setSnackbarProps({
          open: true,
          text: `Error creating ${entityType}`,
          severity: SnackbarType.error,
        })
      }
      if (updatedEntity.state === ApiState.ready) {
        setSnackbarProps({
          open: true,
          text: `Congratulations! Your ${entityType} is ready!`,
          severity: SnackbarType.success,
        })
      }
    }),
  )
}
