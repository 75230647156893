import { Backdrop, Stack } from "@mui/material"
import React from "react"
import DeleteImage from "../../assets/delete.svg"
import { WhiteBox } from "../boxes"
import { MainButton } from "../buttons"
import { BoldText } from "../text"

export const DeletePopup = ({
  onConfirm,
  open,
  onCancel,
  text,
}: {
  onConfirm: () => Promise<void>
  open: boolean
  onCancel: () => void
  text: string
}) => {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={onCancel}
    >
      <WhiteBox width="20vw">
        <Stack
          justifyContent={"center"}
          alignItems={"center"}
          spacing={"1.5rem"}
        >
          <img src={DeleteImage} alt="delete" />
          <BoldText text={text} />

          <Stack justifyContent={"center"} direction={"row"} spacing={"1.5rem"}>
            <MainButton text={"Cancel"} white={true} onClick={onCancel} />
            <MainButton
              text={"Delete"}
              onClick={onConfirm ? onConfirm : onCancel}
            />
          </Stack>
        </Stack>
      </WhiteBox>
    </Backdrop>
  )
}
