import { Backdrop, Stack } from "@mui/material"
import React from "react"
import { BoldText, MainButton, WhiteBox } from "../../components"

export function Popup({
  open,
  handleClose,
  text,
  ctaText,
  headlineText,
  onClick,
}: {
  open: boolean
  handleClose: () => void
  text: React.ReactNode
  ctaText: string
  headlineText: string
  onClick?: () => Promise<void>
}) {
  return (
    <Backdrop
      sx={{ color: "#fff", zIndex: (theme) => theme.zIndex.drawer + 1 }}
      open={open}
      onClick={handleClose}
    >
      <WhiteBox width={"40%"}>
        <Stack justifyContent={"center"} spacing={"1.5rem"}>
          <BoldText text={headlineText} />
          {text}
          <Stack alignItems={"center"}>
            <MainButton
              text={ctaText}
              onClick={onClick ? onClick : handleClose}
            />
          </Stack>
        </Stack>
      </WhiteBox>
    </Backdrop>
  )
}
